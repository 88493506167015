<template>
  <section class="iq-section iq-visitor">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" direction="up">
      <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              style="margin-bottom: 100px"
      >
    <div v-for="item in list" :key="item">
      <div class="iq-visitor__time">{{item.date}}</div>
      <div class="iq-visitor__item" v-for="g in item.list">
        <div class="iq-visitor__item-content">
          <div class="iq-visitor__item-info">{{g.productTitle}}</div>
          <div class="iq-visitor__item-pics">
            <img :src="g.productImg" alt="">
          </div>
        </div>
        <div class="iq-visitor__item-control">{{g.readCount}}</div>
        <div class="iq-visitor__item-avatar" :class="{ 'active': openStatus }">
          <template v-if="list.length>3">
            <div class="iq-visitor__item-button"></div>
          </template>
          <div class="iq-visitor__item-users" v-for="u in g.readList">
            <img :src="u.headPic" alt="">
            <span>{{u.nickname}}</span>
          </div>
        </div>
      </div>
    </div>
      </van-list>
    </van-pull-refresh>
  </section>
</template>

<script>
import {getVisitorList} from "@/api/user";

export default {
  name: 'Visitor',
  data () {
    return {
      openStatus: false,
      loading: false,
      finished: false,
      refreshing: false,
      pageNum: 1,
      pages: 1,
      list: [],
      timestamp: 0,
    }
  },
  mounted () {
  },
  methods: {
    onRefresh () {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad () {
      if (this.refreshing) {
        this.resetTimes();
        this.list = [];
        this.pageNum = 1;
        this.pages = 1;
        this.refreshing = false;
      }
      this.loadList()
    },
    loadList() {
      if (this.pageNum > this.pages) {
        this.loading = false;
        return
      }
      this.loading = true;
      getVisitorList({
        pageNum: this.pageNum,
        timestamp: this.timestamp,
      }).then(data => {
        if (data.success == true) {
          this.pageNum = data.data.current + 1;
          this.pages = data.data.pages;
          this.list.push(...data.data.records);
          if (this.pageNum > this.pages) {
            this.finished = true;
          }
        } else {
          this.finished = true;
        }
      }).finally(() => {
        this.loading = false
      }).catch(() => {
        this.finished = true;
      })
    },
    resetTimes() {
      var time = Date.parse( new Date() ).toString();
      this.timestamp = parseInt(time.substr(0,10));

    },
  },
  created() {
    this.resetTimes()
  },
}
</script>

<style lang="scss" scoped>
  .iq-visitor {
    height: 100%;
    overflow: scroll;
    background: #f7f7f7;

    &__ {

      &time {
        height: 28px;
        line-height: 28px;
        padding: 0 10px 0 35px;
        background: #f7f7f7 url('../assets/icon-time.png') 10px center no-repeat;
        background-size: 16px;
        font-size: 12px;
        color: #999;
      }

      &item {
        margin-bottom: 10px;
        background: #fff;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
        padding: 10px;

        &- {

          &content {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;
          }

          &info {
            flex: 1;
            height: 80px;
            font-size: 12px;
            line-height: 1.5;
          }

          &pics {
            width: 100px;
            height: 80px;
            background: #eee;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          &control {
            font-size: 12px;
            background: url('../assets/icon-me.png') 0 center no-repeat;
            background-size: 14px;
            padding-left: 20px;
            margin: 10px 0;
            color: #999;
          }

          &button {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 100%;
            background: url('../assets/xiangxia.png') center no-repeat;
            background-size: 20px;
          }

          &avatar {
            position: relative;
            height: 72px;
            display: flex;
            flex-wrap: wrap;
            background: #f7f7f7;
            padding: 10px 30px 0 10px;

            &.active {
              height: 100%;
            }

            &:after {
              content: '';
              position: absolute;
              top: -5px;
              left: 2px;
              width: 10px;
              height: 10px;
              background: #f7f7f7;
              transform: rotate(45deg);
            }
          }

          &users {
            margin-right: 10px;
            margin-bottom: 10px;

            span ,
            img {
              display: inline-block;
              vertical-align: middle;
            }

            img {
              width: 20px;
              height: 20px;
              margin-right: 4px;
              border-radius: 50%;
            }

            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
</style>
